import YouTubePlaylist from "@codesweetly/react-youtube-playlist";

const Sailing = () => {
    return (
        <div>
            <h1>Sailing</h1>
            <div style={{ margin: "20px" }}>
                <YouTubePlaylist
                    apiKey={process.env.REACT_APP_YOUTUBE_API}
                    playlistId="PL1vymQHT_JE5UAVSWTTYU2KJDmdryPQ4g"
                    uniqueName="Sailing"
                />
            </div>
        </div>
    )
}

export { Sailing };