import { process } from 'autoprefixer';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCYT7N3JwMZ0DbSryYFDaIDwha2Dfl6_0M",
    authDomain: "wrussell-photo-site.firebaseapp.com",
    databaseURL: "https://wrussell-photo-site-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "wrussell-photo-site",
    storageBucket: "wrussell-photo-site.appspot.com",
    messagingSenderId: "336963802927",
    appId: "1:336963802927:web:b6fcd50967394ee80ff3da",
    measurementId: "G-Y6NKWCXBHB"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
