import YouTubePlaylist from "@codesweetly/react-youtube-playlist";

const Workshops = () => {
    return (
        <div>
            <h1>Videos</h1>
            <div style={{ margin: "20px"}}>
                <YouTubePlaylist
                    apiKey={process.env.REACT_APP_YOUTUBE_API}
                    playlistId="PL1vymQHT_JE6dXVV70-fNESaTCe0yBB1j"
                    uniqueName="Workshops"
                />
            </div>
        </div>
    )
}

export { Workshops };
