import './App.css';
import logo from './logo.jpg';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { Home } from './components/Home';
import { PhotoGallery } from './components/PhotoGallery';
import { Sailing } from './components/Sailing';
import { Commute } from './components/Commute';
import { Workshops } from './components/Workshops';
import { Work } from './components/Work';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    
  })

  const Page = (props) => {
    useEffect(() => {
      document.title = props.title || "";
    }, [props.title]);
    return props.children;
  };

  return (
    <div className="App">
      <Router>
      <div className="profile">
        <div className="profile-picture">
          <img alt='Will Russell' src={logo} />
        </div>
        <h1><Link to="/">Will Russell</Link></h1>
          <h3 className="Email">📧 <a href="mailto:will@willr.uk">will@willr.uk</a></h3>
          <br></br>
          <h3 className="Email"> <a href="https://linkedin.com/in/wrussell1999">Connect with me on LinkedIn</a></h3>
      </div>
      <nav className="NavBar">
        <ul>
          <li className="WorkNav">
            <h2><Link to="/cv">CV</Link></h2>
          </li>
          <li className="WorkshopNav">
            <h2><Link to="/videos">Videos</Link></h2>
          </li>
          <li className="PhotoNav">
            <h2><Link to="/photos">Photos</Link></h2>
          </li>
          <li className="SailingNav">
            <h2><Link to="/sailing">Sailing</Link></h2>
          </li>
        </ul>
      </nav>

        <Switch>
          <Route exact path="/"
            render={(props) => (
              <Page title="Will Russell">
                <Work />
              </Page>
            )}></Route>
          <Route
            path="/cv"
            render={(props) => (
              <Page title="CV | Will Russell">
                <Home {...props}/>
              </Page>
            )}>
          </Route>
          <Route
            path="/videos"
            render={(props) => (
              <Page title="Videos | Will Russell">
                <Workshops {...props} />
              </Page>
            )}>
          </Route>
          <Route
            path="/photos"
            render={(props) => (
              <Page title="Photos | Will Russell">
                <PhotoGallery {...props} />
              </Page>
            )}>
          </Route>  
          <Route
            path="/sailing"
            render={(props) => (
              <Page title="Sailing | Will Russell">
                <Sailing {...props} />
              </Page>
            )}>
          </Route>
         
          <Route
            path="/commute"
            render={(props) => (
              <Page title="Train Times | Will Russell">
                <Commute {...props} />
              </Page>
            )}>
            
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
