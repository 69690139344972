import { useEffect, useState } from 'react';
import axios from 'axios';
import { getData } from 'exif-js';

const Commute = () => {
    
    const [destination, setDestination] = useState("N/A")
    const [origin, setOrigin] = useState("N/A")
    const [departTime, setDepartTime] = useState("N/A");
    const [platformNumber, setPlatformNumber] = useState("N/A");
    useEffect(() => {
        async function getData() {
            const response = await axios.get("http://127.0.0.1:5000/api/trains/royston")
            console.log(response)
            setDestination(response.data.destination);
            setOrigin(response.data.origin);
            setDepartTime(response.data.depart_time);
            setPlatformNumber(response.data.platform);
        }
        getData();
    }, [])

    return (
        <div>
            <h1>Commute</h1>
            <h2>Royston to Blackfriars</h2>
            <p>{origin} to {destination}</p>
            <p>{departTime} on Platform {platformNumber}</p>
        </div>
        )
}

export { Commute };