import ImageGallery from 'react-image-gallery';
import EXIF from "exif-js";
import { useEffect, useRef, useState } from 'react';

const PhotoGallery = () => {

    const data = [
        {
            original: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/CAM02673.jpg?alt=media&token=86756e86-edee-4bf3-859c-ae720f029edc",
            originalTitle: "Train",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/CAM02673.jpg?alt=media&token=86756e86-edee-4bf3-859c-ae720f029edc"
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/7M400237.jpg?alt=media&token=dd67fcdb-3efd-447f-9b1c-94b449e41340",
            originalTitle: "E60",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/7M400237.jpg?alt=media&token=dd67fcdb-3efd-447f-9b1c-94b449e41340"
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/CAM01935.jpg?alt=media&token=95bef05a-aff2-4f33-9798-8b8da8b85e70",
            originalTitle: "DurHack 2022",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/CAM01935.jpg?alt=media&token=95bef05a-aff2-4f33-9798-8b8da8b85e70"
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/CAM07254.jpg?alt=media&token=66024ae8-b328-4b59-bb92-8c9b4a5216ea",
            originalTitle: "Musto Skiff Straight On",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/CAM07254.jpg?alt=media&token=66024ae8-b328-4b59-bb92-8c9b4a5216ea"
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/CAM03786.jpg?alt=media&token=a63618ae-8083-4cea-a13a-5e1bbb119778",
            originalTitle: "New York City",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/CAM03786.jpg?alt=media&token=a63618ae-8083-4cea-a13a-5e1bbb119778"
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/CAM04644.jpg?alt=media&token=0ecfa760-599f-4e93-bfbf-18ae3c325ecb",
            originalTitle: "Driving",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/CAM04644.jpg?alt=media&token=0ecfa760-599f-4e93-bfbf-18ae3c325ecb",
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/CAM06057.jpg?alt=media&token=1a3c628f-6cce-49c4-bab2-c3a2d6e13a56",
            originalTitle: "Hack Cambridge Atlas",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/CAM06057.jpg?alt=media&token=1a3c628f-6cce-49c4-bab2-c3a2d6e13a56"
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/DSC09515.jpg?alt=media&token=27afd3f0-b412-4bcc-9be9-0697ac55e9dd",
            originalTitle: "Fence",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/DSC09515.jpg?alt=media&token=27afd3f0-b412-4bcc-9be9-0697ac55e9dd",
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/20220912-CAM04048.jpg?alt=media&token=bab06099-d4fd-4b31-91f9-8f7fd6592c58",
            originalTitle: "SUP",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/20220912-CAM04048.jpg?alt=media&token=bab06099-d4fd-4b31-91f9-8f7fd6592c58"
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/DSC03902.jpg?alt=media&token=0dcfbf2f-507b-4aac-994e-0774b7128222",
            originalTitle: "Bus",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/DSC03902.jpg?alt=media&token=0dcfbf2f-507b-4aac-994e-0774b7128222",
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/CAM07091.jpg?alt=media&token=234b7f2f-a6f2-4ca6-8a0a-50830b45ba3e",
            originalTitle: "Musto Skiff",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/CAM07091.jpg?alt=media&token=234b7f2f-a6f2-4ca6-8a0a-50830b45ba3e"
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/DSC09394.jpg?alt=media&token=da2b558b-dc55-4514-9478-803c0e94634e",
            originalTitle: "Bassingbourn",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/DSC09394.jpg?alt=media&token=da2b558b-dc55-4514-9478-803c0e94634e"
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/CAM04594.jpg?alt=media&token=0c2a624c-d274-442f-884f-6687bc1be3f1",
            originalTitle: "Car",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/CAM04594.jpg?alt=media&token=0c2a624c-d274-442f-884f-6687bc1be3f1"
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/CAM02713.jpg?alt=media&token=23761844-4035-43c8-adae-a4a7ff712dc5",
            originalTitle: "DurHack 2022",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/CAM02713.jpg?alt=media&token=23761844-4035-43c8-adae-a4a7ff712dc5"
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/DSC09671.jpg?alt=media&token=19251884-0d92-400f-be19-92fcdd5e293c",
            originalTitle: "Autumn Skating",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/DSC09671.jpg?alt=media&token=19251884-0d92-400f-be19-92fcdd5e293c"
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/20220829-CAM01486.jpg?alt=media&token=95416bf0-d8b5-41cd-8085-0a6ecd182130",
            originalTitle: "MLH Team Summit",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/wrussell-photo-site.appspot.com/o/20220829-CAM01486.jpg?alt=media&token=95416bf0-d8b5-41cd-8085-0a6ecd182130"
        }

    ];

    const captionStyle = {
        fontSize: '2em',
        fontWeight: 'bold',
    }


    const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
    }

    return (
        <div>
            <h1>Photos</h1>
            <div style={{ textAlign: "center" }}>
                <div style={{
                    padding: "0 10px"
                }}>
                    <ImageGallery
                        items={data}>
                    </ImageGallery>
                
                </div>
            </div>
        </div>
    )
}

export { PhotoGallery };
